import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { cn, Paginator } from '@divlab/divanui';
import { InView } from 'react-intersection-observer';
import { useDebouncedCallback } from 'use-debounce';

import * as CatalogFiltrator from '@Stores/CatalogFiltrator';
import * as Filtrator from '@Stores/Filtrator';
import * as ModalWindows from '@Stores/ModalWindows';
import useTranslation from '@Queries/useTranslation';
import useSelectFilter from '@Queries/useSelectFilter';
import useDeps from '@Contexts/DI/useDeps';
import { useUpdateComponentStorage } from '@Stores/ComponentStorage';
import ProductSectionsCatalog from '@Components/ProductSectionsCatalog';
import ProductMixedCatalog from '@Components/ProductMixedCatalog';
import DefaultFilters from '@Components/Filters/DefaultFilters';
import MattressesFilters from '@Components/Filters/MattressesFilters';
import Subcategories from '@Components/Subcategories';
import StickyFilters from '@Components/Filters/StickyFilters';
import Button from '@UI/Button';
import UILink from '@UI/Link';
import Message from '@Components/Message';
import ProductCard from '@Components/ProductCard';
import CollectionFilters from './elems/CollectionFilters';
import useNavigation from '@Navigation/useNavigation';
import styles from './DefaultCatalog.module.css';

import type { InlineBannerData } from '@Promo/typings';
import type { FC, HTMLAttributes, MouseEvent } from 'react';
import type { UseInfiniteQueryResult } from '@tanstack/react-query';
import type { GenerateUrl } from '@divlab/divanui';
import type { CatalogData, StructureData } from '@Types/Catalog';
import type { BreadcrumbData } from '@Types/Breadcrumbs';
import type { GroupData } from '@Components/Filters/typings';
import type { PopularLinkData, SubcategoryData } from '@Types/Category';
import type { RenderProduct } from '@Components/ProductMixedCatalog';
import type { FilterView } from '@Types/Filters';

export interface PageData {
  analyticsTitle: string;
  breadcrumbs: BreadcrumbData[];
  title?: string;
}

export interface DefaultCatalogProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  banners?: InlineBannerData[];
  defaultStructure?: StructureData[];
  isInSpotlight?: boolean;
  category: UseInfiniteQueryResult<CatalogData>;
  slug: string;
  needPaginator?: boolean;
  pageData: PageData;
  renderProduct?: RenderProduct;
  isModels?: boolean;
  cnFilter?: string;
  popularLinks?: PopularLinkData[];
  groups?: GroupData[];
  rubrics?: SubcategoryData[][];
  isLoadingPage?: boolean;
}

const DefaultCatalog: FC<DefaultCatalogProps> = (props) => {
  const {
    className,
    banners,
    defaultStructure,
    isInSpotlight,
    category,
    slug,
    needPaginator,
    pageData,
    renderProduct,
    isModels,
    cnFilter,
    popularLinks,
    groups,
    rubrics,
    isLoadingPage,
    ...restProps
  } = props;
  const { t } = useTranslation();
  const lastPage = category.data.pages[category.data.pages.length - 1];
  const { pathname, search } = useNavigation();
  const [firstPage] = category.data?.pages || [];
  const enabledInfiniteScroll = useRef(firstPage.page === 1);
  const refFilters = useRef<HTMLDivElement>(null);
  const filtrator = CatalogFiltrator.useFiltrator();
  const resetLink = Filtrator.useResetLink('catalog');
  const hasFilters = filtrator.filters?.length > 0 || filtrator.sort?.length > 0;
  const totalCount = firstPage.activeProductsTotalCount || firstPage.productsTotalCount;
  const { analytics } = useDeps();
  const resetAllUrl = `${pathname}${resetLink}`;
  const { selectFilter } = useSelectFilter(slug || '');
  const needChangePage = useRef(category.data?.pages.length > 1);
  const [currentPage, setCurrentPage] = useState(firstPage.page);
  const [filtersInView, setFiltersInView] = useState(true);

  const generatePaginatorUrl: GenerateUrl = useCallback(
    (params) => {
      const pageParam = params.page;
      const chunks = pathname.split('/');
      const lastChunk = chunks[chunks.length - 1];
      const newChunk = `page-${pageParam}`;
      const urlHasPage = lastChunk.match(/page-[0-9]{0,}/);

      if (pageParam < 2) {
        if (urlHasPage) delete chunks[chunks.length - 1];
      } else if (urlHasPage) {
        chunks[chunks.length - 1] = newChunk;
      } else {
        chunks.push(newChunk);
      }

      return `/${chunks.filter(Boolean).join('/')}${search}`;
    },
    [pathname, search],
  );

  const linkToNextPage = useMemo(() => {
    return generatePaginatorUrl({ page: lastPage.page + 1 });
  }, [generatePaginatorUrl, lastPage.page]);

  const collectionFilters = useMemo(() => {
    if (!hasFilters) return [];

    const rubricFilter = filtrator.filters.find((filter) => filter.theme === 'rubric-checkboxes');

    let rubricValues = [];
    rubricFilter?.items.forEach((item) => {
      const values = filtrator.parameterValues.filter((parameterValue) => {
        return parameterValue.parameterId === item.parameterId;
      });
      rubricValues = rubricValues.concat(values);
    });

    return rubricValues;
  }, [filtrator, hasFilters]);

  const handleOpenFilters = useCallback(
    (e: MouseEvent, selectedFilterId: string, filterView: FilterView) => {
      ModalWindows.open('Filters', {
        selectedFilterId,
        entityId: 'catalog',
        onChangeFilters: selectFilter,
        filterView,
      });
    },
    [selectFilter],
  );

  const handleMore = useCallback(async () => {
    if (category.isFetchingNextPage || !category.hasNextPage) return;

    await category.fetchNextPage();
  }, [category]);

  const handleChangeVisibleRow = useDebouncedCallback((page: number) => {
    if (!enabledInfiniteScroll.current) return;
    if (!needChangePage.current) return;

    if (page === currentPage) return;

    setCurrentPage(page);

    const path = generatePaginatorUrl({ page });

    window.history.replaceState({}, null, path);

    analytics.dispatchEvent('page.virtual.open', {
      path,
      title: pageData.title,
    });
  }, 200);

  const handleButtonMoreClick = useCallback(async () => {
    await handleMore();
    window.history.replaceState({}, null, generatePaginatorUrl({ page: lastPage.page + 1 }));
  }, [lastPage.page, handleMore, generatePaginatorUrl]);

  const filtersProps = {
    className: cn({ [styles.bordered]: !rubrics?.length }, cnFilter),
    count: totalCount,
    groups: groups,
    popularLinks: popularLinks,
    onOpen: handleOpenFilters,
  };

  const handleResetAllFilters = useCallback(() => {
    Filtrator.resetAll('catalog');
    const allAppliedFilters = Filtrator.getAppliedFiltersNames('catalog');

    analytics.dispatchEvent('filters.clear', {
      allAppliedFilters,
      label: 'Все фильтры',
      status: 'не закреп',
    });
  }, [analytics]);

  const handleChangeView = useCallback(
    (inView: boolean) => {
      if (!inView || !enabledInfiniteScroll.current) return;
      needChangePage.current = true;

      handleMore();
    },
    [handleMore],
  );

  ModalWindows.useSSRModals(
    [
      {
        id: 'Filters',
        data: {
          selectedFilterId: 'all',
          entityId: 'catalog',
          onChangeFilters: selectFilter,
        },
      },
    ],
    category.isSuccess && !!firstPage.filters,
  );

  const handleScroll = useCallback(() => {
    if (!refFilters.current) return;

    const offsetBottom = refFilters.current.offsetTop + refFilters.current.clientHeight;
    const height = document.documentElement.scrollTop;

    setFiltersInView(height <= offsetBottom);
  }, []);

  useUpdateComponentStorage({
    filters: (
      <StickyFilters
        count={totalCount}
        visible={!filtersInView}
        className={cnFilter}
        onOpen={handleOpenFilters}
        isLoadingPage={isLoadingPage}
        filtersOffsetTop={refFilters?.current?.offsetTop}
      />
    ),
  });

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  if (!category.isSuccess) return null;

  return (
    <div
      {...restProps}
      className={cn(
        styles.catalog,
        { [styles.hidePaginator]: enabledInfiniteScroll.current },
        className,
      )}
    >
      {filtrator?.categories.length > 0 && (
        <div className={styles.rubricsWrapper}>
          <div className={styles.rubricsContainer}>
            <Subcategories className={styles.rubrics} subcategories={filtrator.categories} />
          </div>
        </div>
      )}

      {collectionFilters.length > 0 && (
        <CollectionFilters className={styles.collectionFilters} values={collectionFilters} />
      )}

      {hasFilters && (
        <div className={styles.filtersWrapper} ref={refFilters}>
          {firstPage.isMatrasyCategory ? (
            <MattressesFilters {...filtersProps} />
          ) : (
            <DefaultFilters {...filtersProps} />
          )}
        </div>
      )}
      {firstPage.productsTotalCount > 0 ? (
        <>
          {isModels ? (
            <ProductSectionsCatalog
              className={styles.catalog}
              analyticsTitle={pageData.analyticsTitle}
              category={category}
              onChangeVisibleRow={handleChangeVisibleRow}
              renderProduct={renderProduct}
            />
          ) : (
            <ProductMixedCatalog
              className={styles.catalogWrapper}
              category={category.data}
              banners={banners}
              analyticsTitle={pageData.analyticsTitle}
              defaultStructure={defaultStructure}
              isInSpotlight={isInSpotlight}
              onChangeVisibleRow={handleChangeVisibleRow}
              renderProduct={
                renderProduct
                  ? renderProduct
                  : ({ product, view }) => {
                      return <ProductCard product={product} view={view} />;
                    }
              }
            />
          )}

          {category.hasNextPage && (
            <>
              <InView
                threshold={0}
                as='div'
                root={null}
                rootMargin='200px 0px'
                onChange={handleChangeView}
              >
                <div className={styles.loader} />
              </InView>
              <div className={styles.moreWrapper}>
                <Button
                  preventDefault
                  theme='dirty'
                  wide
                  className={styles.moreButton}
                  waiting={category.isFetchingNextPage}
                  to={linkToNextPage}
                  onClick={handleButtonMoreClick}
                  data-testid='show-more-button'
                >
                  {t('ui.show-more')}
                </Button>
              </div>
            </>
          )}

          {needPaginator && firstPage.pageCount > 1 && (
            <div className={styles.wrapperPaginator}>
              <Paginator
                now={lastPage.page}
                total={firstPage.pageCount}
                generateUrl={generatePaginatorUrl}
              />
            </div>
          )}
        </>
      ) : (
        <Message
          className={styles.message}
          title={t('category.not-found.title')}
          text={
            <>
              {t('category.not-found.try-change')}{' '}
              <UILink
                view='primary'
                underlined
                to={filtrator.withoutUrl ? undefined : resetAllUrl}
                onClick={handleResetAllFilters}
              >
                {t('category.not-found.clear')}
              </UILink>{' '}
              {t('category.not-found.find-products')}
            </>
          }
        />
      )}
    </div>
  );
};

export default memo(DefaultCatalog);
