import { memo, useMemo } from 'react';
import { cn, Gallery } from '@divlab/divanui';

import Link from '@Navigation/Link';
import styles from './InformationTabsNavigation.module.css';

import type { NavigationItemData } from '@Types/NavigationItem';
import type { FC, HTMLAttributes } from 'react';

export interface InformationTabsNavigationProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  navigation: NavigationItemData[];
}

const InformationTabsNavigation: FC<InformationTabsNavigationProps> = (props) => {
  const { navigation, ...restProps } = props;

  const slideIndex = useMemo(() => {
    return navigation.findIndex((item) => item.active);
  }, [navigation]);

  return (
    <nav {...restProps}>
      <div className={styles.galleryWrapper}>
        <Gallery slideIndex={slideIndex} className={styles.gallery} centered>
          {navigation.map((item) => (
            <div className={styles.navigationItem} key={item.href} data-testid={item.testId}>
              <Link
                to={item.href}
                className={cn(styles.navigationLink, { [styles.active]: item.active })}
              >
                {item.name}
              </Link>
            </div>
          ))}
        </Gallery>
      </div>
    </nav>
  );
};

export default memo(InformationTabsNavigation);
